<template>
  <div id="inphograficScreen"  :style="{ backgroundImage: backgroundUrl }">
    <!-- <div v-show="$parent.showDebug" id="mouseCoords" ref="mouseCoords"
    :style="{left: $parent.mouseX + 'px', top: ($parent.mouseY-30) + 'px'}">
      {{xcoor}} - {{ycoor}}
    </div> -->
    <!-- STANDBY MODE -->
    <VideoBackground v-show="standbyMode" :playlist="this.options.standByVideos" />

    <template v-if="!standbyMode">
      <!-- PLAYING MODE -->
      <VideoDouob v-if="options && options.background.video" :src="options.background.video.url" :autoplay="true"
        :loop="true" />

      <div v-if="showTutorial" id="screenTutorial"
        style="background-image: url(/assets/infographic/tutorialscreen.png)"
        class="is-flex is-flex-direction-column is-justify-content-flex-end is-align-items-center">
      </div>
      <!--Videos de los points-->
      <div v-for="(point, index) in points" :key="point.id">
        <div v-if="points.length > 0">
          <transition @before-enter="beforeEnter" @enter="enter" @leave="leave">
            <video v-show="isVideoPointPlaying[index]" :ref="'pointVideo' + index" class="primer-plano"
              @ended="CloseEndedVideoPoint(index)" :id="`video${index}`"
              crossorigin="anonymous">
              <source :src="GetVideo(index).url" />
            </video>
          </transition>
        </div>
      </div>

      <div id="points" v-show="!showTutorial">
        <div class="point" v-for="(point, i) in points" :key="point.id" :class="{ open: point.open }"
          :style="{ '--player-color': point.usersReading.length > 0 ? '#' + point.usersReading[0].color : 'inherit' }">
          <div class="dot" :style="{
            top: `${point.posy * windowHeight}px`,
            left: `${point.posx * windowWidth}px`,
          }" :ref="point.id">{{ i + 1 }}</div>
          <div class="outercircle" :class="{ outlined: point.usersReading.length == 0 }" :style="{
            top: `${point.posy * windowHeight}px`,
            left: `${point.posx * windowWidth}px`,
          }" :ref="point.id"></div>
          <div class="pointTitle" v-show="isVideoPointPlaying[i]" :style="{
            top: `${point.posy * windowHeight}px`,
            left: `${point.posx * windowWidth}px`,
          }">
            {{ point.title }}
          </div>
        </div>
      </div>

      <!-- PUNTEROS DE LOS JUGADORES -->
      <div id="players">
        <div v-for="player in $store.state.space.players" class="player" v-show="ShowPlayer(player)" :key="player.dbid"
          v-bind:style="{
            top: `${player.screeny * windowHeight}px`,
            left: `${player.screenx * windowWidth}px`,
          }">
          <div class="point" :class="{ clicked: player.clicked }" :style="{ '--player-color': '#' + player.color }">

            <svg width="66" height="67" viewBox="0 0 66 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="33.2993" cy="33.3999" r="12.1538" fill="var(--player-color)" />
              <line x1="33.8379" y1="10.2305" x2="33.8379" y2="0.230469" stroke="var(--player-color)"
                stroke-width="5" />
              <path d="M33.8379 66.5L33.8379 56.5" stroke="var(--player-color)" stroke-width="5" />
              <line x1="56" y1="33.9385" x2="66" y2="33.9385" stroke="var(--player-color)" stroke-width="5" />
              <path d="M0 33.9385L10 33.9385" stroke="var(--player-color)" stroke-width="5" />
              <circle cx="33.2998" cy="33.4003" r="23.3076" stroke="#FAFAFA" stroke-width="2" />
              <!-- Circulo cargando -->
              <circle class="circleLoader" cx="33.2998" cy="33.4003" r="23.3076" stroke="var(--player-color)"
                stroke-width="5" :style="{ 'stroke-dashoffset': player.hoverCountdown }" />
            </svg>
          </div>

        </div>
      </div>
    </template>

    <qr-box />
    <user-list :autohide="false" />

    <!--preload assets videos -->
    <preload-assets ref="PreloadAssets" />
  </div>



</template>

<script>
import Vue from "vue";
import gsap from "gsap";
import "./screenInfographic.scss";
import VideoBackground from "./VideoBackground.vue";
import VideoDouob from "../VideoDouob.vue";
import qrBox from './qrBox.vue';
import UserList from './userList.vue';
import PreloadAssets from "../PreloadAssets.vue";

export default {
  components: {
    VideoBackground,
    VideoDouob,
    qrBox,
    UserList,
    PreloadAssets,
  },
  data() {
    return {
      points: [],
      deltaTime: null,
      timePrevFrame: Date.now(),
      timeToOpen: 1000, // ms
      snapDistance: 75, // px ?
      windowWidth: 1920,
      windowHeight: 1080,

      isVideoPointPlaying: [],
      showTutorial: false,
      mouseCoordsX: 0,
      mouseCoordsY: 0,
      pointerSmoothness: 0.5,//valor normalizado
      hostPlayer: null,
    }
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },

  computed: {
    backgroundUrl() {
      return this.options.background.image ? 'url(' + encodeURI(this.options.background.image.url) + ')' : "url(/assets/trivia/background.png)" ;
    },
    xcoor() {
      return (this.$parent.mouseX / window.innerWidth).toFixed(2);
    },
    ycoor() {
      return (this.$parent.mouseY / window.innerHeight).toFixed(2);
    },
    standbyMode() {
      return this.$store.state.space.players.length == 0;
    },
    isAnyHost() {
      return this.$parent.hostControl;
    },
    playersCount() {
      return this.$store.state.space.players.length;
    }
  },
  watch: {
    // showTutorial(newState, oldState) {
    //   // if (newState && (newState != oldState)) {

    //   // }
    // },
    standbyMode(newVal) {
      this.showTutorial = !newVal
      if (!newVal) {
        // Cambió a falso, o sea que se unió el primer jugador
        this.$sfxPlay('waitingroomstart', { fadein: true })
        this.$sfxPlay('gameplaymusic', { loop: true, fadein: true })
      } else {
        // Se fueron todos los jugadores
        this.$sfxStop('gameplaymusic');
        this.$sfxStop('waitingroomstart');
      }
    },
    isAnyHost(newVal) {
      if (!newVal) {
        this.hostPlayer = null;
        console.log("El host solto el control")
      }
      else {
        this.hostPlayer = this.$store.state.space.players.find(x => x.role == "tour-leader")
        console.log(this.hostPlayer.username + " es host y tomo el control")
        //Deseleccionar puntos
        this.points.forEach((point) => { point.open = false, point.usersReading.length = 0 });
        this.$forceUpdate();
      }
    },
  },

  methods: {
    ShowPlayer(player) {
      if (player.reading) return false;
      if (!player.usingGyro) return false;
      const isHost = player.role == 'tour-leader';
      if (this.$parent.hostControl && !isHost) return false;
      return true;
    },
    onMouseMove(evt) {
      console.log(evt)
    },
    Update() {
      // Function called each frame
      // ie 60fps
      this.deltaTime = Date.now() - this.timePrevFrame;
      this.timePrevFrame = Date.now()
      this.$store.state.space.players.forEach((player) => {
        // Players loop
        if (player.reading || !player.usingGyro) return

        let playerScreenx = player.x * this.windowWidth;
        let playerScreeny = player.y * this.windowHeight;
        let hoveringTimePreFrame = player.hoveredTime;
        this.points.forEach((point) => {
          // Points loop
          let pointRect = this.$refs[point.id][0].getBoundingClientRect();
          let pointScreenx = pointRect.x + (pointRect.width / 2)
          let pointScreeny = pointRect.y + (pointRect.height / 2)
          let distance = Math.sqrt(Math.pow(playerScreenx - pointScreenx, 2) + Math.pow(playerScreeny - pointScreeny, 2));
          if (distance < this.snapDistance) {
            // Snap player to point
            player.screenx = point.posx;
            player.screeny = point.posy;
            if (player.hoveredTime < this.timeToOpen) {
              // Hovering timer
              if (player.hoveredTime == 0) {
                // Hover started
                this.$sfxPlay('cursorhoverpoint')
              }
              player.hoveredTime += this.deltaTime;
            } else if (!player.reading) {
              // Abriendo point
              player.hoveredTime = 0;
              this.OpenPoint(point, player)
            }
          } else if (player.hoveredTime == 0) {
            //player.screenx = player.x;
            //player.screeny = player.y;
            //Movimiento amortiguado del puntero
            player.screenx = player.x * (1 - this.pointerSmoothness) + player.screenx * this.pointerSmoothness;
            player.screeny = player.y * (1 - this.pointerSmoothness) + player.screeny * this.pointerSmoothness;
          }
        })
        if (player.hoveredTime > 0 && hoveringTimePreFrame == player.hoveredTime) {
          // Como no cambió despues de loopear todos los puntos
          // Asumo que no snapeó, entonces lo reseteo a 0
          player.hoveredTime = 0
          // Hover canceled
          this.$sfxStop('cursorhoverpoint', { fadeout: false })
        }
        player.hoverCountdown = (1 - (player.hoveredTime / this.timeToOpen)) * 150
      })
      window.requestAnimationFrame(this.Update);
    },

    GetVideo(pointIndex) {
      if (this.options.points[pointIndex].video) {
        return this.options.points[pointIndex].video;
      }
      else return "";
    },

    PlayVideoPoint(pointIndex) {
      this.showTutorial = false;
      //Evita que se reproduzca el video de un point ya abierto o que no tenga
      if (this.isVideoPointPlaying[pointIndex] || !this.points[pointIndex].video) return;
      //Desactiva todos los points que no son el nuevo seleccionado
      for (let i = 0; i < this.points.length; i++) {
        if (pointIndex != i && this.isVideoPointPlaying[i]) {
          this.isVideoPointPlaying[i] = false;
        }
      }
      //Reproduce el video del nuevo point seleccionado
      const video = this.$refs["pointVideo" + pointIndex][0];
      video.load();
      video.pause();
      video.currentTime = 0;
      video.play();
      this.isVideoPointPlaying[pointIndex] = true;

      video.ontimeupdate = () => {
        //console.log(video.currentTime, video.duration);
        if (isNaN(video.duration)) return;
        if (video.duration - video.currentTime < 1) {
          //console.log("Le queda menos de 1 segundo")
          this.isVideoPointPlaying[pointIndex] = false;
          this.$forceUpdate();
        }
      }
    },

    CloseEndedVideoPoint(pointIndex) {
      this.isVideoPointPlaying[pointIndex] = false;
    },

    GetIndexPointById(id) {
      for (let i = 0; i < this.points.length; i++) {
        if (this.points[i].id == id) return i;
      }
    },

    OpenPoint(point, player) {
      this.PlayVideoPoint(this.GetIndexPointById(point.id))
      this.$forceUpdate();
      console.log('Abriendo', { to: player.socketid, msg: "openInfo", data: player.readingid })
      player.reading = true;
      player.readingid = point.id;
      point.usersReading.unshift(player);
      point.open = true;

      const selectedByHost = this.$parent.hostControl && player.role == 'tour-leader'
      if (selectedByHost) {
        // Modo host control, envio mensaje a todos los jugadores
        const sockets = this.$store.state.space.players.map(x => x.socketid)
        this.$socket.client.emit("messageToPlayer", { to: sockets, msg: "openInfo", data: { readingid: player.readingid, selectedByHost } })
      } else {
        // Solo le envio a este player
        this.$socket.client.emit("messageToPlayer", { to: player.socketid, msg: "openInfo", data: { readingid: player.readingid, selectedByHost } })
      }
      this.$sfxStop('waitingroomstart');
      this.$sfxPlay('selectpointspace')
    },

    //Videos animation
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    enter(el, done) {
      gsap.to(el, {
        opacity: 1,
        duration: 1,
        onComplete: done,
      });
    },
    leave(el, done) {
      gsap.to(el, {
        opacity: 0,
        duration: 1,
        onComplete: done,
      });
    },
  },

  sockets: {
    playerJoined() {
      this.showTutorial = false;
    },
    playerLeave(){
      if (this.$store.state.space.players.length == 0) {
        this.showTutorial = true;
      }
    },
    callibrated() {
      this.showTutorial = false
    },
    pos(data) {
      this.$store.commit("space/AddPlayerData", {
        dbid: data.dbid, // Para encontrar al usuario
        x: data.x,
        y: data.y,
        usingGyro: true
      })
    },
    closeInfo(data) {
      this.$store.commit("space/AddPlayerData", {
        dbid: data.dbid, // Para encontrar al usuario
        reading: false,
      })
      const point = this.points.find(x => x.id == data.pointid)
      if (point) {
        console.log("User closed point", point)
        // Saco al usuario de la lista de lectores
        const index = point.usersReading.indexOf(point.usersReading.find(x => x.dbid == data.dbid))
        point.usersReading.splice(index, 1)
        if (point.usersReading.length == 0) {
          point.open = false
        }
        // Mueve a los usuaios a la lista de points si el tour-leader cierra su point
        // const socketPlayer = this.$store.state.space.players.find(player => player.dbid == data.dbid);
        // if (socketPlayer.role == 'tour-leader' && this.$parent.hostControl) {
        //   this.$store.state.space.players.forEach((player) => {
        //     if (player.role != 'tour-leader') {
        //       this.$socket.client.emit("messageToPlayer", { to: player.socketid, msg: "CloseModalWithScreen" })
        //     }
        //   })
        // }
        this.$forceUpdate();
      }
      this.$sfxPlay('closepointspace')
    },
    usingGyro(data) {
      console.log('usingGyro', data)
      this.$store.commit("space/AddPlayerData", {
        dbid: data.dbid, // Para encontrar al usuario
        usingGyro: data.gyro,
      })
    },
    ExperienceEdited() {
      setTimeout(() => {
        this.points = this.options.points
        this.$forceUpdate();
      }, 500);
    },
    openInfoButton(data) {
      const player = this.$store.state.space.players.find(x => x.dbid == data.dbid)
      const point = this.points.find(x => x.id == data.pointId)
      this.OpenPoint(point, player)
    },
    SetPointByHostToNewPlayers(data) {
      const player = this.$store.state.space.players.find(x => x.dbid == data.dbid)
      const pointOpened = this.points.find(x => x.id == this.hostPlayer.readingid)
      this.$socket.client.emit("messageToPlayer", {
        to: player.socketid,
        msg: "OpenPointOpenedByHost",
        data: { point: pointOpened }
      })
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    });


    this.points = this.options.points
    this.points.forEach((el) => {
      el.open = false;
      el.usersReading = [];

      // Random para puntos sin coordenadas
      if (!el.posx) el.posx = Math.random()
      if (!el.posy) el.posy = Math.random()
    });

    // Cuando un juegador se une, le seteo unas datas de base
    this.$store.dispatch("space/SetNewPlayersExtraData", {
      x: 0.5, // Posicion normalizada del puntero
      y: 0.5,
      screenx: .5 * this.windowWidth, // Posicion real en pantalla
      screeny: .5 * this.windowHeight, // Puede ser distinta a X,Y cuando snapea o está leyendo
      reading: false,
      readingid: null,
      hoverid: null,
      hoveredTime: 0,
      hoverCountdown: 0,
      visible: true,
      usingGyro: false,
      clicked: false,
    })

    Vue.nextTick(() => {
      this.Update();
    });

    for (let i = 0; i < this.points.length; i++) {
      this.isVideoPointPlaying[i] = false;
    }
    this.$sfxLoad(this.options.sounds,
      [
        'waitingroomstart',
        'gameplaymusic',
        'cursorhoverpoint',
        'selectpointspace',
        'closepointspace'
      ]
    )

    // Código comentado porque tiraba un error en el fetch 408
    // De todas maneras una vez que corra ya va a tener los assets cargados
    // preloaded videos
    // console.log("** ", this.points.filter(x => x.video).map(x => x.video.url),)
    const assets = [
      ...this.options.standByVideos.map(x => x.video.url),
      ...this.points.filter(x => x.video).map(x => x.video.url),
      "/assets/infographic/tutorialscreen.png",
    ]
    this.$refs["PreloadAssets"].StartPreloading(assets)
  },

};
</script>





<style scoped>
#videoPosition {
  position: absolute;
  height: 10px;
  bottom: 0px;
  left: 0;
  background: rgb(134, 235, 126);
}

.primer-plano {
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
</style>
